import $ from 'jquery'
import Splide from '@splidejs/splide'

function init() {}
function ready() {
  const interval = sessionStorage.getItem('visit') ? '3000' : '6000'

  const splideOptions = {
    autoplay: true,
    type: 'fade',
    pauseOnHover: false,
    pauseOnFocus: false,
    pagination: false,
    fixedHeight: 'calc(100vh - 98px)',
    arrows: false,
    interval: interval,
    speed: 2000,
    breakpoints: {
      767: {
        fixedHeight: 'calc(100vh - 72px)',
      },
    },
  }

  new Splide('.js-top-mv-splide', splideOptions).mount()
}

export default {
  init() {
    // トップページでのみ処理を実行
    if (!$('html').hasClass('page-top')) {
      return
    }

    init()
    $(function () {
      ready()
    })
  },
}
