import $ from 'jquery'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import focusLoopStack from './_focusLoopStack.js'

const modalClose = function () {
  // 最上位のモーダル要素
  const modal = focusLoopStack.stack.pop()
  const $currentTarget = $(modal.targetEl)
  const $thisBtn = $(modal.openBtnEl)

  searchToggleClose($thisBtn, $currentTarget, function () {
    if ($thisBtn && $thisBtn[0]) {
      $thisBtn[0].focus()
    }
  })
}

const modalInit = function ($target, $thisBtn, $searchBtn, closeFunction) {
  // フォーカスループ開始
  // 開いたモーダル記録
  /*
  {
    id: string
    targetEl: HTMLElement
    openBtnEl: HTMLElement
    afterFocusEl: HTMLElement
    addFocus: {
      el: HTMLElement
      matchMedia: --to-md | --md | --lg | etc
    } <Array>
    closeFunction: function
  }
  */
  focusLoopStack.stack.push({
    id: 'searchBox',
    type: 'searchBox',
    targetEl: $target[0],
    openBtnEl: $thisBtn[0],
    afterFocusEl: null,
    addFocus: [{ el: $searchBtn[0] }],
    closeFunction: closeFunction,
  })

  // scroll lock
  disableBodyScroll($target[0])
}

const searchToggleClose = function ($thisBtn, $target, callback) {
  $thisBtn.removeClass('is-active')
  $thisBtn.addClass('is-close-enter')
  $thisBtn.attr({
    'aria-expanded': 'false',
  })

  $('html').removeClass('is-search-open')

  $target.hide(0, function () {
    $thisBtn.removeClass('is-close-enter')
    $target.css({ display: '' })
    $target.removeClass('is-active')
    $target.attr({
      'aria-hidden': 'true',
    })
  })

  if (callback && typeof callback === 'function') {
    callback()
  }

  enableBodyScroll($target[0])

  focusLoopStack.delById('searchBox')
}

const searchToggleOpen = function ($thisBtn, $target) {
  $('html').addClass('is-search-open')

  $target.show()
  $target.addClass('is-active')
  $target.css({ display: '' })
  $target.attr({ 'aria-hidden': 'false' })

  // ボタン処理
  $thisBtn.addClass('is-active')
  $thisBtn.attr({ 'aria-expanded': 'true' })

  // モーダルオープン処理
  modalInit($target, $thisBtn, $thisBtn, modalClose)

  $target.find('.js-search-overlay').on('click', function () {
    $thisBtn.trigger('click')
  })
}

function init() {
  $(document).on('click', '.js-search-toggle', function (e) {
    e.preventDefault()
    const $this = $(this)
    const $target = $('#' + $this.attr('aria-controls'))

    if ($this.hasClass('is-active')) {
      searchToggleClose($this, $target)
    } else {
      searchToggleOpen($this, $target)
    }
  })
}
function ready() {}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
