// Modalコンポーネント用のJSファイル

import $ from 'jquery'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import getUniqueStrId from '../_helper/_getUniqueStrId'
import focusLoopStack from './_focusLoopStack.js'

function init() {
  // モーダルを閉じる関数
  const modalClose = function (_targetNameId) {
    // 閉じるモーダルを取得
    const modal = (function () {
      if (_targetNameId) {
        return focusLoopStack.getAndDelById(_targetNameId)
      } else {
        return focusLoopStack.stack.pop()
      }
    })()

    // モーダル内の要素を取得
    const currentTarget = modal.targetEl
    const focusAfterClosed = modal.afterFocusEl
    const isScrollEnable = modal.isScrollEnable
    const targetNameId = modal.id
    const modalTypeLength = focusLoopStack.getTypeLength('modal')
    const $currentTarget = $(currentTarget)
    const modalVideo = document.getElementsByClassName('js-modal-video')

    // モーダルを非表示にする
    $currentTarget.animate(
      {
        opacity: 0,
      },
      300,
      function () {
        $currentTarget.removeClass('is-active').css({
          opacity: '',
        })

        // 開いているモーダルがなければbodyのクラスを削除
        if (modalTypeLength === 0) {
          $('html').removeClass('is-modal-active')
          $('html').removeClass('is-modal-scroll-enable')
        }

        // 閉じるときにフォーカスを移す要素が指定されていればフォーカスを移す
        if (focusAfterClosed) {
          focusAfterClosed.focus()
        }

        // スクロールを有効化する
        if (!isScrollEnable) enableBodyScroll($currentTarget[0])

        // モーダル内の動画を停止する
        if (modalVideo.length) {
          for (let i = 0; i < modalVideo.length; i++) {
            modalVideo[i].pause()
          }
        }
      }
    )

    // 閉じるボタンのクリックイベントを解除
    $currentTarget.off(`click.${targetNameId}`)
  }

  // モーダルを開くボタンをクリックしたときの処理
  $(document).on('click', '.js-modal_btn', function (e) {
    e.preventDefault()

    // ボタン要素と対応するモーダル要素を取得
    const $btn = $(this)
    const target = $btn.attr('data-target')
    const targetNameId = `modal-${target}`
    const $target = $(target)
    const focusAfterClosed = this
    const focusFirst = $btn.attr('data-focus-first')
    const isScrollEnable = $btn.attr('data-scroll-enable') === 'true'

    // モーダルが存在すれば処理を行う
    if ($target[0]) {
      // 開いたモーダルをスタックに追加して管理するオブジェクトを生成し、スタックにpushする
      focusLoopStack.stack.push({
        id: targetNameId,
        type: 'modal',
        targetEl: $target[0],
        openBtnEl: $btn[0],
        afterFocusEl: focusAfterClosed,
        addFocus: [],
        closeFunction: modalClose,
        isScrollEnable: isScrollEnable,
      })
      // bodyにクラスを追加してスクロールを制限する
      $('html').addClass('is-modal-active')
      if (isScrollEnable) $('html').addClass('is-modal-scroll-enable')

      // モーダルを表示する
      $target.addClass('is-active').css({
        opacity: 0,
      })
      $target.animate(
        {
          opacity: 1,
        },
        300,
        function () {
          $target.css({
            opacity: '',
          })
        }
      )

      // フォーカスを移す要素が指定されている場合はその要素にフォーカスを移す
      if ($target.find(focusFirst)[0]) {
        $target.find(focusFirst)[0].focus()
      } else {
        // 指定がなければ、閉じるボタンがあればその要素に、なければモーダル内のフォーカス可能な要素の最初の要素にフォーカスを移す
        if ($target.find('.js-modal_close')[0]) {
          $target.find('.js-modal_close')[0].focus()
        } else {
          $target.find(':_focusable')[0].focus()
        }
      }

      // スクロールを制限する
      if (!isScrollEnable) {
        disableBodyScroll($target[0])
      }

      // 閉じるボタンのクリックイベントを設定する
      $target.on(`click.${targetNameId}`, '.js-modal_close', function (e) {
        e.preventDefault()
        modalClose(targetNameId)
      })

      // モーダルの外側をクリックしたときも閉じるようにする
      $target.on(`click.${targetNameId}`, '.js-modal_overlay', function (e) {
        e.preventDefault()
        modalClose(targetNameId)
      })
    }
  })
}

function ready() {
  window.addEventListener('DOMContentLoaded', function () {
    // モーダルを開くボタンを取得
    const modalLists = document.querySelectorAll(
      '.js-modal_btn:not(.is-initialized)'
    )
    for (let i = 0; i < modalLists.length; i++) {
      const modalBtn = modalLists[i]
      const btnDataTarget = modalBtn.getAttribute('data-target')
      const btnId = modalBtn.getAttribute('id')
      const contents = document.querySelector(btnDataTarget)
      // ボタンとモーダルの要素が正しく設定されていれば、アクセシビリティのためにidや属性を設定し、クラスを追加する
      if (contents && contents.classList.contains('js-modal_contents')) {
        if (!btnId) {
          modalBtn.setAttribute('id', getUniqueStrId('a11y-modal'))
        }
        contents.setAttribute('aria-modal', true)
        contents.setAttribute('role', 'dialog')
        modalBtn.classList.add('is-initialized')
      }
    }
  })
}

export default {
  init() {
    init()
    ready()
  },
}
