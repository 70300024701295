import $ from 'jquery'
import component from './_component'

const init = () => {}

const ready = () => {
  if ($('.js-header').length === 0) return

  // スクロール状態を保持する変数
  let isScrolled = window.pageYOffset > 0

  // ヘッダーの高さを計算する
  const calculateHeight = () => {
    const isMobile = component.matchMedia['--to-md'].matches
    if (isMobile && isScrolled) {
      return 56
    } else if (isMobile && !isScrolled) {
      return 72
    } else if (!isMobile && isScrolled) {
      return 60
    } else if (!isMobile && !isScrolled) {
      return 98
    }
    return 72
  }

  // ヘッダーの高さをCSS変数にセットする
  const setHeaderHeight = () => {
    const height = calculateHeight()
    document.documentElement.style.setProperty('--header-height', `${height}px`)
  }

  // MediaQueryのリスナーを追加する
  const addMediaQueryListeners = () => {
    const mediaQueries = ['--to-md', '--md-lg', '--lg']

    mediaQueries.forEach((query) => {
      component.matchMedia[query].addListener((mql) => {
        if (mql.matches) setHeaderHeight()
      })
    })
  }

  // スクロール状態に応じてヘッダーの高さを変更する
  const observeHeaderScroll = () => {
    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        isScrolled = !entry.isIntersecting
        setHeaderHeight()
      })
    }

    const options = {
      rootMargin: '0px',
      threshold: 1.0,
    }

    const observer = new IntersectionObserver(handleIntersect, options)

    // 目印要素の作成と配置
    const createSentinel = () => {
      const sentinel = document.createElement('div')
      sentinel.style.position = 'absolute'
      sentinel.style.top = '0'
      sentinel.style.left = '0'
      sentinel.style.width = '100%'
      sentinel.style.height = '1px'
      document.body.appendChild(sentinel)
      return sentinel
    }

    const sentinel = createSentinel()
    observer.observe(sentinel)
  }

  setHeaderHeight()
  addMediaQueryListeners()
  observeHeaderScroll()
}

export default {
  init() {
    init()
    $(() => {
      ready()
    })
  },
}
