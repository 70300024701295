import $ from 'jquery'

function init() {}
function ready() {
  const $pagetop = $('.js-pagetop')
  $(window)
    .on('scroll', function () {
      if ($(this).scrollTop() > 200) {
        $pagetop.fadeIn().css('display', 'flex')
      } else {
        $pagetop.fadeOut()
      }

      const height = $(document).height(),
        position = window.innerHeight + $(window).scrollTop(),
        footer = $('.js-footer-height').height()
      if (height - position < footer) {
        $pagetop.addClass('is-absolute')
      } else {
        $pagetop.removeClass('is-absolute')
      }
    })
    .trigger('scroll')
}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
