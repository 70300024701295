import $ from 'jquery'

function init() {}
function ready() {
  if (sessionStorage.getItem('visit')) {
    $('.js-start-animation').hide()
  } else {
    sessionStorage.setItem('visit', 'true')
    setTimeout(function () {
      $('.js-start-animation-inner').fadeIn(1600)
    }, 500)
    setTimeout(function () {
      $('.js-start-animation').fadeOut(500)
    }, 3000)
  }
}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
